/* open-sans-300 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('fonts/open-sans/open-sans-300.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-300.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/open-sans/open-sans-regular.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-regular.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('fonts/open-sans/open-sans-500.woff2') format('woff2'),
    url('fonts/open-sans/open-sans-500.woff') format('woff');
}

/* open-sans-600 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('fonts/open-sans/open-sans-600.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-600.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/open-sans/open-sans-700.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-700.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800 - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: url('fonts/open-sans/open-sans-800.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-800.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300italic - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: url('fonts/open-sans/open-sans-300italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-300italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/open-sans/open-sans-italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-500italic - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 500;
  src: url('fonts/open-sans/open-sans-500italic.woff2') format('woff2'),
    url('fonts/open-sans/open-sans-500italic.woff') format('woff');
}

/* open-sans-600italic - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: url('fonts/open-sans/open-sans-600italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-600italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700italic - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/open-sans/open-sans-700italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-700italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800italic - vietnamese_latin-ext_latin_hebrew_greek-ext_greek_cyrillic-ext_cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: url('fonts/open-sans/open-sans-800italic.woff2') format('woff2'),
    /* Chrome 26+, Opera 23+, Firefox 39+ */ url('fonts/open-sans/open-sans-800italic.woff')
      format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
